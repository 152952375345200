import React, { Component } from 'react'

import Scroll from 'react-scroll'

import IndexPage from '../components/_JeuxDeCartes'
import SetLanguageFromPage from '../config/i18n/SetLanguageFromPage'

class Index extends Component {
  render() {
    return <IndexPage {...this.props}/>
  }
}

export default SetLanguageFromPage()(Index)
