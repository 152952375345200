import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import { StyleSheet, css } from 'aphrodite'
import Scroll from 'react-scroll'
import Helmet from 'react-helmet'

import AltertineLayout from './layouts/AltertineLayout'

import Button from '@material-ui/core/Button'

import Classes from '../styles/classes'
import i18next from '../config/i18n/context'
import { withNamespaces } from 'react-i18next'

const styles = StyleSheet.create({
  footer: {
    backgroundColor: '#002337'
  },
  button: {
    display: 'block',
    margin: '1em 0'
  }
})
/*
"jeuxdecarte":{
    "title":"Jeux de cartes ",
    "description":"Notre club accueille depuis plusieurs années deux communautés de joueurs de cartes.",

    "sub_title":"Club de bridge",

    "sub_title_description1":"Lundi et jeudi de 13h45 à 18h",
    "sub_title_description2":"Renseignements et inscriptions",
    "sub_title_description3":"Theo x  au +32 477 30 00 29",

    "sub_title_description4":"Vendredi de 13h45 à 18h ",
    "sub_title_description5":"Renseignements et inscriptions",
    "sub_title_description6":"David de maeyer au +32 486 59 50 50",

    "sub_title_description7":"Dimanche de 13h45 à 18h",
    "sub_title_description8":"Renseignements et inscriptions",
    "sub_title_description9":"Madame Pêche : +32 475 95 67 82",

    "sub_title2":"Club de Wist",

    "sub_title2_description1":"Le mardi de 20 à 23h",
    "sub_title2_description2":"Renseignements et inscriptions",
    "sub_title2_description3":"x x  au +32 477 00 00 00"
  }
  **/
const IndexPage = ({ t }) => {
  const lang =
    i18next.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng)
  console.log('language home', lang)
  return (
    <AltertineLayout image="/assets/img/jeux-de-cartes.jpeg">
      <Helmet
        title={t('jeuxdecarte.meta.title')}
        meta={[
          {
            name: 'description',
            content:t('jeuxdecarte.meta.description')
          },
          { name: 'charset', content: 'UTF-8' }
        ]}
      />
      <section className="project_descr">
        <div className="container mt-100">
          <div className="row center">
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <h2 className="mb-50">{t('jeuxdecarte.title')}</h2>
                {
                  /*
                <p className="module-subtitle">
                  {t('jeuxdecarte.description')}
                </p>
                **/
                }
              </div>
            </div>
          </div>

          <div className="row center" style={{ marginBottom: '7em' }}>
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <p style={{ textAlign: 'left'}}>
                {t('jeuxdecarte.title3_description1')}
              </p >

              <p style={{ textAlign: 'left'}}>
                {t('jeuxdecarte.title3_description2')}
              </p >

              <p style={{ textAlign: 'left'}}>
                {t('jeuxdecarte.title3_description3')}
              </p >

              <p style={{ textAlign: 'left'}}>
              {t('jeuxdecarte.title3_contact_name')}<br />
              {t('jeuxdecarte.title3_contact_tel')}<br />
              {t('jeuxdecarte.title3_contact_email')}<br />
              </p>
              
            </div>
          </div>
{/*
          <div className="row center" style={{ marginTop: '5em' }}>
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <p className="module-subtitle">
                  {t('jeuxdecarte.sub_title')}
                </p>
              </div>
            </div>
          </div>
          <div className="row center" style={{ marginBottom: '7em' }}>
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <p>
               {t('jeuxdecarte.sub_title_description1')}
               </p>
               <p>
               {t('jeuxdecarte.sub_title_description2')}:
               </p>
               <p >
               {t('jeuxdecarte.sub_title_description3')}
              </p>
              <br />

              <p>
               {t('jeuxdecarte.sub_title_description4')}
               </p>
               <p>
               {t('jeuxdecarte.sub_title_description5')}:
               </p>
               <p >
               {t('jeuxdecarte.sub_title_description6')}
              </p>
              <br />

              <p>
               {t('jeuxdecarte.sub_title_description7')}
               </p>
               <p>
               {t('jeuxdecarte.sub_title_description8')}:
               </p>
               <p >
               {t('jeuxdecarte.sub_title_description9')}
              </p>
              <br />
            </div>
          </div>

          <div className="row center" style={{ marginTop: '5em' }}>
            <div className="col-md-8 col-md-offset-2 col-sm-12">
              <div className="section-title">
                <p className="module-subtitle">
                  {t('jeuxdecarte.sub_title2')}
                </p>
              </div>
            </div>
          </div>

          <div className="row center" style={{ marginBottom: '7em' }}>
            <div className="col-md-10 col-md-offset-1 wow fadeInUp">
              <p>
               {t('jeuxdecarte.sub_title2_description1')}
               </p>
               <p>
               {t('jeuxdecarte.sub_title2_description2')}
               </p>
               <p >
               {t('jeuxdecarte.sub_title2_description3')}
              </p>
              <br />
            </div>
          </div>
      **/}

        </div>
      </section>
    </AltertineLayout>
  )
}

export default withNamespaces('home')(IndexPage)
